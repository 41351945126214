import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink,
  useHistory,
} from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import About from "./screens/About";
import logo from "./assets/logo.png";
import CSVUpload from "./screens/customer/CSVUpload";
import CSVUploadJob from "./screens/job/CSVUploadJob";
import CSVUploadNurse from "./screens/user/nurse/CSVUpload";
import CSVUploadManager from "./screens/user/manager/CSVUpload";
import LoginForm from "./screens/Login";
import { Fragment, useEffect, useState } from "react";
import Dashboard from "./screens/Dashboard";
import CustomerTable from "./screens/customer/CustomerTable";
import JobTableView from "./screens/job/JobTableView";
import UserView from "./screens/user/UserView";
import LocationDetails from "./screens/location/LocationDetails";
import { useAuth, AuthContextProvider } from "./context/AuthContext";
import { getOrganizationDetails } from "./api_url/organizationTable";
import { socket } from "./api_url/socket";
import dp from "./assets/dp.jpg";
import { ChartView } from "./components/ChartView";
import Calendar from "./screens/schedule/Calendar";
import Open from "./OpenAi";
import SideTab from "./components/SideTab";

function App() {
  const [user, setUser] = useState(undefined);
  const [screen, setScreen] = useState("home");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { authToken, userId, removeAuthToken } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState(true);

  const history = useHistory();

  const userNavigation = [{ name: "Profile", href: "/profile" }];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setUser(undefined);
    const check = async () => {
      await getOrganizationDetails(authToken, userId)
        .then((res) => {
          setUser(res);
          socket?.emit("setup", res);
          socket?.on("connected", () => console.log("connected", ""));
          setLoadingScreen(false);
        })
        .catch((e) => {
          if (e) {
            setUser(null);
            setLoadingScreen(false);
            removeAuthToken();
          }
        });
    };
    check();
  }, [authToken, removeAuthToken, userId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const logout = () => {
    removeAuthToken();
  };

  const navItems = [
    { name: "Home", action: () => setScreen("home"), active: screen === "home" },
    { name: "About", action: () => setScreen("about"), active: screen === "about" },
    { name: "Manager", action: () => window.open("https://nursd-flow-dev-api-web.vercel.app", "_blank") },
    { name: "Admin", action: () => setScreen("login") },
  ];

  const dropdownItems = [
    { name: "Manager", action: () => window.open("https://nursd-flow-dev-api-web.vercel.app", "_blank") },
    { name: "Admin", action: () => setScreen("login") },
  ];

  const footerLinks = [
    { name: "About", action: () => setScreen("about") },
    { name: "Privacy Policy", action: () => setScreen("privacy") },
    { name: "Terms of Use", action: () => setScreen("terms") },
  ];

  return (
    <div style={{ maxWidth: "100%", overflowX: "hidden", display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {loadingScreen ? (
        <div className="flex justify-center items-center mt-10">
          Loading....
        </div>
      ) : user ? (
        <Router>
          {screen === "privacy" || screen === "terms" ? (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <NavLink
                  to="/"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                >
                  <div
                    onClick={() => history.push("/")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        width: isMobile ? "80px" : "100px",
                        height: isMobile ? "20px" : "25px",
                      }}
                    />
                  </div>
                </NavLink>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "0.5rem" : "0",
                  }}
                >
                  <NavLink
                    to="/about"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>About</p>
                  </NavLink>
                  <NavLink
                    to="/user"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>User</p>
                  </NavLink>
                  <NavLink
                    to="/patients"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>Patients</p>
                  </NavLink>
                  <NavLink
                    to="/jobs"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>Jobs</p>
                  </NavLink>
                  <Menu as="div" className="mx-3">
                    <Menu.Button
                      className="flex items-center text-black font-weight-500 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                      style={{ padding: isMobile ? "0.5rem" : "0 20px" }}
                    >
                      <div className="flex items-center">
                        <div className="flex flex-col gap-[2px]">
                          <div className="w-5 h-[2px] bg-black"></div>
                          <div className="w-5 h-[2px] bg-black"></div>
                          <div className="w-5 h-[2px] bg-black"></div>
                        </div>
                      </div>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {dropdownItems.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={item.action}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <div className="relative">
                    <Menu as="div" className="mx-3">
                      <div>
                        <Menu.Button
                          className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full"
                            src={dp}
                            alt=""
                            style={{
                              width: isMobile ? "24px" : "32px",
                              height: isMobile ? "24px" : "32px",
                            }}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          style={{ width: isMobile ? "160px" : "192px" }}
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            <p
                              onClick={() => logout()}
                              className={classNames(
                                "block px-4 py-2 text-sm cursor-pointer text-gray-700"
                              )}
                            >
                              Log Out
                            </p>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </header>
              <main style={{ flex: 1 }}>
                <SideTab initialTab={screen} />
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </div>
          ) : (
            <>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <NavLink
                  to="/"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                >
                  <div
                    onClick={() => history.push("/")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        width: isMobile ? "80px" : "100px",
                        height: isMobile ? "20px" : "25px",
                      }}
                    />
                  </div>
                </NavLink>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "0.5rem" : "0",
                  }}
                >
                  <NavLink
                    to="/about"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>About</p>
                  </NavLink>
                  <NavLink
                    to="/user"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>User</p>
                  </NavLink>
                  <NavLink
                    to="/patients"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>Patients</p>
                  </NavLink>
                  <NavLink
                    to="/jobs"
                    style={{
                      color: "black",
                      fontWeight: "500",
                      padding: isMobile ? "0.5rem" : "0 20px",
                    }}
                    activeStyle={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    <p>Jobs</p>
                  </NavLink>
                  <Menu as="div" className="mx-3">
                    <Menu.Button
                      className="flex items-center text-black font-weight-500 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                      style={{ padding: isMobile ? "0.5rem" : "0 20px" }}
                    >
                      <div className="flex items-center">
                        <div className="flex flex-col gap-[2px]">
                          <div className="w-5 h-[2px] bg-black"></div>
                          <div className="w-5 h-[2px] bg-black"></div>
                          <div className="w-5 h-[2px] bg-black"></div>
                        </div>
                      </div>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {dropdownItems.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={item.action}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <div className="relative">
                    <Menu as="div" className="mx-3">
                      <div>
                        <Menu.Button
                          className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="rounded-full"
                            src={dp}
                            alt=""
                            style={{
                              width: isMobile ? "24px" : "32px",
                              height: isMobile ? "24px" : "32px",
                            }}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          style={{ width: isMobile ? "160px" : "192px" }}
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            <p
                              onClick={() => logout()}
                              className={classNames(
                                "block px-4 py-2 text-sm cursor-pointer text-gray-700"
                              )}
                            >
                              Log Out
                            </p>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </header>
              <main style={{ flex: 1 }}>
                <Switch>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/about" component={About} />
                  <Route path="/patients" component={CustomerTable} />
                  <Route path="/jobs" component={JobTableView} />
                  <Route path="/user" component={UserView} />
                  <Route path="/upload-jobs" component={CSVUploadJob} />
                  <Route path="/upload-patient" component={CSVUpload} />
                  <Route path="/upload-nurse" component={CSVUploadNurse} />
                  <Route path="/upload-manager" component={CSVUploadManager} />
                  <Route path="/profile" component={LocationDetails} />
                  <Route path="/report" component={ChartView} />
                  <Route path="/schedule" component={Calendar} />
                  <Route path="/ai-schedule" component={Open} />
                </Switch>
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </>
          )}
        </Router>
      ) : (
        <Router>
          {screen === "home" ? (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                  onClick={() => setScreen("home")}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: isMobile ? "80px" : "100px",
                      height: isMobile ? "20px" : "25px",
                    }}
                  />
                </div>
                {isMobile ? (
                  <Menu as="div" className="relative">
                    <Menu.Button
                      className="flex items-center px-3 py-2 rounded-md bg-gray-800 text-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span>Menu</span>
                      <svg
                        className="ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {navItems.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={item.action}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  item.active ? "text-blue-600 font-bold" : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div
                    className="flex items-center"
                    style={{
                      flexDirection: "row",
                      gap: "1.5rem",
                      textAlign: "center",
                      width: "auto",
                    }}
                  >
                    <p
                      className="text-black font-medium cursor-pointer"
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Home
                    </p>
                    <p
                      className="text-black font-medium cursor-pointer"
                      onClick={() => setScreen("about")}
                      style={{ fontSize: "1rem" }}
                    >
                      About
                    </p>
                    <Menu as="div" className="relative">
                      <Menu.Button
                        className="flex items-center text-black font-medium text-sm focus:outline-none"
                        style={{ height: "100%" }}
                      >
                        <div className="flex items-center">
                          <div className="flex flex-col gap-[2px]">
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                          </div>
                        </div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {dropdownItems.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <p
                                  onClick={item.action}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </p>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </header>
              <main style={{ flex: 1 }}>
                <Dashboard />
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </div>
          ) : screen === "about" ? (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                  onClick={() => setScreen("home")}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: isMobile ? "80px" : "100px",
                      height: isMobile ? "20px" : "25px",
                    }}
                  />
                </div>
                {isMobile ? (
                  <Menu as="div" className="relative">
                    <Menu.Button
                      className="flex items-center px-3 py-2 rounded-md bg-gray-800 text-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span>Menu</span>
                      <svg
                        className="ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {navItems.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={item.action}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  item.active ? "text-blue-600 font-bold" : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div
                    className="flex items-center"
                    style={{
                      flexDirection: "row",
                      gap: "1.5rem",
                      textAlign: "center",
                      width: "auto",
                    }}
                  >
                    <p
                      className="text-black font-medium cursor-pointer"
                      onClick={() => setScreen("home")}
                      style={{ fontSize: "1rem" }}
                    >
                      Home
                    </p>
                    <p
                      className="text-black font-medium cursor-pointer"
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      About
                    </p>
                    <Menu as="div" className="relative">
                      <Menu.Button
                        className="flex items-center text-black font-medium text-sm focus:outline-none"
                        style={{ height: "100%" }}
                      >
                        <div className="flex items-center">
                          <div className="flex flex-col gap-[2px]">
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                          </div>
                        </div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {dropdownItems.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <p
                                  onClick={item.action}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </p>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </header>
              <main style={{ flex: 1 }}>
                <About />
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </div>
          ) : screen === "privacy" || screen === "terms" ? (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                  onClick={() => setScreen("home")}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: isMobile ? "80px" : "100px",
                      height: isMobile ? "20px" : "25px",
                    }}
                  />
                </div>
                {isMobile ? (
                  <Menu as="div" className="relative">
                    <Menu.Button
                      className="flex items-center px-3 py-2 rounded-md bg-gray-800 text-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span>Menu</span>
                      <svg
                        className="ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {navItems.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <p
                                onClick={item.action}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  item.active ? "text-blue-600 font-bold" : "text-gray-700",
                                  "block px-4 py-2 text-sm cursor-pointer"
                                )}
                              >
                                {item.name}
                              </p>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div
                    className="flex items-center"
                    style={{
                      flexDirection: "row",
                      gap: "1.5rem",
                      textAlign: "center",
                      width: "auto",
                    }}
                  >
                    <p
                      className="text-black font-medium cursor-pointer"
                      onClick={() => setScreen("home")}
                      style={{ fontSize: "1rem" }}
                    >
                      Home
                    </p>
                    <p
                      className="text-black font-medium cursor-pointer"
                      onClick={() => setScreen("about")}
                      style={{ fontSize: "1rem" }}
                    >
                      About
                    </p>
                    <Menu as="div" className="relative">
                      <Menu.Button
                        className="flex items-center text-black font-medium text-sm focus:outline-none"
                        style={{ height: "100%" }}
                      >
                        <div className="flex items-center">
                          <div className="flex flex-col gap-[2px]">
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                            <div className="w-5 h-[2px] bg-black"></div>
                          </div>
                        </div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {dropdownItems.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <p
                                  onClick={item.action}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                  )}
                                >
                                  {item.name}
                                </p>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </header>
              <main style={{ flex: 1 }}>
                <SideTab initialTab={screen} />
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <header
                className="flex justify-between px-5 py-5 shadow-md"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "center" : "stretch",
                  padding: isMobile ? "1rem" : "1.25rem 1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                  onClick={() => setScreen("home")}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: isMobile ? "80px" : "100px",
                      height: isMobile ? "20px" : "25px",
                    }}
                  />
                </div>
                <p
                  className="text-black font-medium cursor-pointer"
                  onClick={() => setScreen("home")}
                  style={{
                    fontSize: isMobile ? "1rem" : "1rem",
                    padding: isMobile ? "0.5rem 1rem" : "0",
                    backgroundColor: isMobile ? "#f0f0f0" : "transparent",
                    borderRadius: isMobile ? "5px" : "0",
                  }}
                >
                  Back to home
                </p>
              </header>
              <main style={{ flex: 1 }}>
                <LoginForm />
              </main>
              <footer
                style={{
                  backgroundColor: "#2d3748",
                  color: "#e2e8f0",
                  padding: isMobile ? "1.5rem 1rem" : "2rem",
                  textAlign: "center",
                  borderTop: "1px solid #4a5568",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: isMobile ? "1rem" : "2rem",
                    marginBottom: "1rem",
                  }}
                >
                  {footerLinks.map((link) => (
                    <p
                      key={link.name}
                      onClick={link.action}
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        cursor: "pointer",
                        color: "#a0aec0",
                        transition: "color 0.3s ease",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#ffffff")}
                      onMouseLeave={(e) => (e.target.style.color = "#a0aec0")}
                    >
                      {link.name}
                    </p>
                  ))}
                </div>
                <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem", margin: 0 }}>
                  © {new Date().getFullYear()} NURSD Health. All rights reserved.
                </p>
              </footer>
            </div>
          )}
        </Router>
      )}
    </div>
  );
}

export default function AppWithAuthProvider() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}











// import "./App.css";
// import {
//   Route,
//   BrowserRouter as Router,
//   Switch,
//   NavLink
//   // useHistory
// } from "react-router-dom";
// // import Home from "./screens/Home";
// import { Menu, Transition } from "@headlessui/react";
// import About from "./screens/About";
// // import icon from "./assets/icon.png";
// import logo from "./assets/logo.png";
// import CSVUpload from "./screens/customer/CSVUpload";
// import CSVUploadJob from "./screens/job/CSVUploadJob";
// import CSVUploadNurse from "./screens/user/nurse/CSVUpload";
// import CSVUploadManager from "./screens/user/manager/CSVUpload";
// import LoginForm from "./screens/Login";
// import { Fragment, useEffect, useState } from "react";
// import Dashboard from "./screens/Dashboard";
// import CustomerTable from "./screens/customer/CustomerTable";
// import JobTableView from "./screens/job/JobView";
// import UserView from "./screens/user/UserView";
// import LocationDetails from "./screens/location/LocationDetails";
// import { useAuth, AuthContextProvider } from "./context/AuthContext";
// import { getOrganizationDetails } from "./api_url/organizationTable";
// import { socket } from "./api_url/socket";
// import dp from "./assets/dp.jpg";
// import { ChartView } from "./components/ChartView";
// import Calendar from "./screens/schedule/Calendar";
// import Open from "./OpenAi";

// function App() {
//   // const history = useHistory();
//   const [user, setUser] = useState(undefined);
//   const [screen, setScreen] = useState("home");

//   const { authToken, userId, removeAuthToken } = useAuth();
//   const [loadingScreen, setLoadingScreen] = useState(true);

//   const userNavigation = [{ name: "Profile", href: "/profile" }];

//   function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
//   }

//   // console.log(authToken);

//   useEffect(() => {
//     setUser(undefined);
//     const check = async () => {
//       await getOrganizationDetails(authToken, userId)
//         .then((res) => {
//           setUser(res);
//           socket?.emit("setup", res);
//           socket?.on("connected", () => console.log("connected", ""));
//           setLoadingScreen(false);
//         })
//         .catch((e) => {
//           if (e) {
//             setUser(null);
//             setLoadingScreen(false);
//             removeAuthToken();
//           }
//         });
//     };
//     check();
//   }, [authToken, removeAuthToken, userId]);

//   const logout = () => {
//     removeAuthToken();
//   };

//   return (
//     <div>
//       {loadingScreen ? (
//         <div className="flex justify-center items-center mt-10">
//           Loading....
//         </div>
//       ) : user ? (
//         <Router>
//           <header className="flex justify-between px-5 py-5 shadow-md">
//             <NavLink
//               to="/"
//               style={(isActive) => ({
//                 display: "flex",
//                 alignItems: "center"
//               })}
//             >
//               <div style={{ display: "flex", alignItems: "center" }}>
//                 {/* <img src={icon} alt="icon" width={40} height={40} /> */}
//                 <img src={logo} alt="logo" width={100} height={25} />
//               </div>
//             </NavLink>
//             <div style={{ display: "flex", alignItems: "center" }}>
//               {/* <NavLink
//                 to="/"
//                 style={(isActive) => ({
//                   color: isActive ? "blue" : "black",
//                   fontWeight: isActive ? "bold" : "500",
//                   paddingLeft: 20,
//                 })}
//               >
//                 <p>Home</p>
//               </NavLink> */}
//               <NavLink
//                 to="/about"
//                 style={(isActive) => ({
//                   color: isActive ? "blue" : "black",
//                   fontWeight: isActive ? "bold" : "500",
//                   paddingLeft: 20
//                 })}
//               >
//                 <p>About</p>
//               </NavLink>
//               <NavLink
//                 to="/user"
//                 style={(isActive) => ({
//                   color: isActive ? "blue" : "black",
//                   fontWeight: isActive ? "bold" : "500",
//                   paddingLeft: 20
//                 })}
//               >
//                 <p>User</p>
//               </NavLink>
//               <NavLink
//                 to="/patients"
//                 style={(isActive) => ({
//                   color: isActive ? "blue" : "black",
//                   fontWeight: isActive ? "bold" : "500",
//                   paddingLeft: 20
//                 })}
//               >
//                 <p>Patients</p>
//               </NavLink>
//               <NavLink
//                 to="/jobs"
//                 style={(isActive) => ({
//                   color: isActive ? "blue" : "black",
//                   fontWeight: isActive ? "bold" : "500",
//                   paddingLeft: 20
//                 })}
//               >
//                 <p>Jobs</p>
//               </NavLink>
//               {/* <p
//                 style={{
//                   color: "black",
//                   fontWeight: "500",
//                   paddingLeft: 20,
//                 }}
//                 className="cursor-pointer"
//                 onClick={() => {
//                   logout();
//                 }}
//               >
//                 LogOut
//               </p> */}
//               <div className="">
//                 {/* Profile dropdown */}
//                 <Menu as="div" className="relative mx-3">
//                   <div>
//                     <Menu.Button
//                       className="flex max-w-xs items-center
//                        rounded-full bg-gray-800 text-sm focus:outline-none 
//                        focus:ring-2 focus:ring-white focus:ring-offset-2
//                         focus:ring-offset-gray-800"
//                     >
//                       <span className="sr-only">Open user menu</span>
//                       <img className="h-8 w-8 rounded-full" src={dp} alt="" />
//                     </Menu.Button>
//                   </div>
//                   <Transition
//                     as={Fragment}
//                     enter="transition ease-out duration-100"
//                     enterFrom="transform opacity-0 scale-95"
//                     enterTo="transform opacity-100 scale-100"
//                     leave="transition ease-in duration-75"
//                     leaveFrom="transform opacity-100 scale-100"
//                     leaveTo="transform opacity-0 scale-95"
//                   >
//                     <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//                       {userNavigation.map((item) => (
//                         <Menu.Item key={item.name}>
//                           {({ active }) => (
//                             <a
//                               href={item.href}
//                               className={classNames(
//                                 active ? "bg-gray-100" : "",
//                                 "block px-4 py-2 text-sm text-gray-700"
//                               )}
//                             >
//                               {item.name}
//                             </a>
//                           )}
//                         </Menu.Item>
//                       ))}
//                       <Menu.Item>
//                         <p
//                           onClick={() => logout()}
//                           className={classNames(
//                             "block px-4 py-2 text-sm cursor-pointer text-gray-700"
//                           )}
//                         >
//                           Log Out
//                         </p>
//                       </Menu.Item>
//                     </Menu.Items>
//                   </Transition>
//                 </Menu>
//               </div>
//             </div>
//           </header>
//           <Switch>
//             <Route path="/" exact component={Dashboard} />
//             <Route path="/about" component={About} />
//             <Route path="/patients" component={CustomerTable} />
//             <Route path="/jobs" component={JobTableView} />
//             <Route path="/user" component={UserView} />
//             <Route path="/upload-jobs" component={CSVUploadJob} />
//             <Route path="/upload-patient" component={CSVUpload} />
//             <Route path="/upload-nurse" component={CSVUploadNurse} />
//             <Route path="/upload-manager" component={CSVUploadManager} />
//             <Route path="/profile" component={LocationDetails} />
//             <Route path="/report" component={ChartView} />
//             <Route path="/schedule" component={Calendar} />
//             <Route path="/ai-schedule" component={Open} />
//           </Switch>
//         </Router>
//       ) : (
//         <Router>
//           {screen === "home" ? (
//             <div>
//               <header className="flex justify-between px-5 py-5 shadow-md">
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   {/* <img src={icon} alt="icon" width={40} height={40} /> */}
//                   <img src={logo} alt="logo" width={100} height={25} />
//                 </div>
//                 <div className="flex space-x-5">
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     style={{
//                       color: "blue",
//                       fontWeight: "bold"
//                     }}
//                   >
//                     Home
//                   </p>
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     onClick={() => setScreen("about")}
//                   >
//                     About
//                   </p>
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     onClick={() => {
//                       //   try {
//                       //     let response = await fetch("http://192.168.29.215:3000/");
//                       //     console.log(response)
//                       //   } catch (error) {
//                       //     console.log(error, "error")
//                       //   }
//                       //
//                       window.open(
//                         "https://nursd-flow-dev-api-web.vercel.app",
//                         "_blank"
//                       );
//                     }}
//                   >
//                     Manager
//                   </p>
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     onClick={() => setScreen("login")}
//                   >
//                     Admin
//                   </p>
//                 </div>
//               </header>

//               <Dashboard />
//             </div>
//           ) : screen === "about" ? (
//             <div>
//               <header className="flex justify-between px-5 py-5 shadow-md">
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   {/* <img src={icon} alt="icon" width={40} height={40} /> */}
//                   <img src={logo} alt="logo" width={100} height={25} />
//                 </div>
//                 <div className="flex space-x-5">
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     onClick={() => setScreen("home")}
//                   >
//                     Home
//                   </p>
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     style={{
//                       color: "blue",
//                       fontWeight: "bold"
//                     }}
//                   >
//                     About
//                   </p>
//                   <p
//                     className="text-black font-medium cursor-pointer"
//                     onClick={() => setScreen("login")}
//                   >
//                     Login
//                   </p>
//                 </div>
//               </header>

//               <About />
//             </div>
//           ) : (
//             <div>
//               <header className="flex justify-between px-5 py-5 shadow-md">
//                 <div style={{ display: "flex", alignItems: "center" }}>
//                   {/* <img src={icon} alt="icon" width={40} height={40} /> */}
//                   <img src={logo} alt="logo" width={100} height={25} />
//                 </div>
//                 <p
//                   className="text-black font-medium cursor-pointer"
//                   onClick={() => setScreen("home")}
//                 >
//                   Back to home
//                 </p>
//               </header>

//               <LoginForm />
//             </div>
//           )}
//         </Router>
//       )}
//     </div>
//   );
// }

// // export default App;
// export default function AppWithAuthProvider() {
//   return (
//     <AuthContextProvider>
//       <App />
//     </AuthContextProvider>
//   );
// }

// import React from 'react'
// import PDF from "./PDF"
// import ScheduleComponent from './ScheduleComponent'
// import OpenAi from './OpenAi'

// export default function App() {
//   return (
//     <OpenAi />
//   )
// }
